<template>
  <div>
    <div class="container">
      <div class="tournament-info-breadcrumb">
        <div class="mt-1 row text-center">
          <div class="col-12 text-start">
            <a href="">ホーム</a> <i class="fa fa-angle-right"></i> ホーム
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="tournament-info-next-prev">
        <div class="mt-1 row text-center">
          <div class="col-6 text-start">
            <i class="fa fa-angle-left"></i><span class="ml-3 mx-2"><a href="">三菱 全日本テニス選手権95thへ</a></span>
          </div>
          <div class="col-6 text-end">
            <span class="mr-3"><a href="">ソニー生命カップ全国レディーステニス大会</a></span><i class="fa fa-angle-right mx-2"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-4">
      <div class="card">
        <div class="card-header">
          <svg class="bi bi-megaphone-fill" fill="#888888" height="14.1" viewBox="0 0 16 16" width="16"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"/>
          </svg>
          第57回島津全日本室内テニス選手権大会 <span class="badge bg-light custom-bg-light bg-time-duration">終了</span>
        </div>
        <div class="card-body tournament-card-body">
          <div class="col-6 mx-auto mt-5 mb-5">
            <div id="test-l-1" aria-labelledby="stepper1trigger1" class="bs-stepper-pane active" role="tabpanel">
              <div class="row">
                <div class="col">
                  <label for="inputPeriod">入力期間</label>
                  <input class="form-control" name="inputPeriod" placeholder="2020/12/01〜2020/12/08" type="text">
                </div>
                <div class="col">
                  <label for="inputInquiryPhoneNumber">お問い合わせ電話番号</label>
                  <input class="form-control" name="inputInquiryPhoneNumber" placeholder="03-0000-0000" type="text">
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="inpuHostPrefecture">開催都道府県</label>
                  <input class="form-control" name="inpuHostPrefecture" placeholder="東京都" type="text">
                </div>
                <div class="col">
                  <label for="inputInquiryEmail">お問い合わせメール</label>
                  <input class="form-control" name="inputInquiryEmail" placeholder="info@jta.com" type="text">
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label for="inputOrganizer">主催団体</label>
                  <input class="form-control" name="inputOrganizer" placeholder="東京都テニス協会" type="text">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      isHome: true
    }
  },
  methods: {
    tournamentInfo(e) {
      e.preventDefault()
      this.isHome = !this.isHome
    }
  }
}

</script>

<style scoped>

label[for="inputPeriod"], label[for="inputInquiryPhoneNumber"] {
  margin-top: 0px;
}

label {
  margin-top: 15px;
  color: #333333;
  font-size: 16px;
  font-weight: 450;
}

.card-header {
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 31px;
  color: #333333;
  padding-left: 20px;
  padding-right: 20px;
}

.card svg, h6 > i {
  margin-right: 5px;
}

.card-body {
  padding: 20px 30px;
}

.card-body > p {
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #333333;
}

.card-body > p:last-child {
  margin-bottom: 0px;
}

table {
  margin-bottom: 0;
}

.tournament-card-body {
  padding: 0px;
}

.tournament-card-body td {
  padding-right: 30px;
  padding-left: 30px;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #333333;
  vertical-align: middle;
}

.custom-bg-light {
  background: #EBEBEB !important;
  color: #888888;
}

.bg-time-duration {
  margin-left: 10px;
  padding: 0.30em 0.60em;
}

input {
  background-color: #EFF2F7;
  height: 48px;
}

.tournament-info-breadcrumb {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 17px;
  position: relative;
  font-size: 13px;
  font-weight: bold;
}

.tournament-info-breadcrumb a {
  color: #333333;
}

.fa-angle-right, .fa-angle-left {
  color: #888888;
  padding-left: 6px;
  padding-right: 6px;
}

.tournament-info-next-prev {
  padding-top: 20px;
  border-top: 1px solid #D3D8EA;
  position: relative;
}

.tournament-info-next-prev a {
  font-weight: bold;
  color: #333333;
}

li {
  list-style-type: none;
}

</style>